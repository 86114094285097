<template>
  <div class="excursion">
    <div
      class="card"
      v-for="item in EXCURSIONS_AND_ROUTES"
      :key="item.id"
    >
      <object-card
        type="icon"
        width="488px"
        height="275px"
        :item="item"
        :img="item.images[0]"
        :title="item.title"
        :category="item.type.name"
        @click.native="goToObject(item)"
      />
    </div>
    <div class="tags">
      <swiper
        :options="{
          slidesPerView: 'auto',
          touchRatio: 2,
          freeMode: true
        }"
      >
        <swiper-slide
          v-for="(item, index) in TYPE"
          :key="`${index + 3 + 's'}`"
          @click.native="changeBadge(item)"
        >
          <tag
            :title="item.name"
            :active="item.type === activeBadge.type"
          >
          </tag>
        </swiper-slide>
        <template
          v-if="activeBadge.subtypes"
        >
          <swiper-slide
            v-for="(subtype, index) in activeBadge.subtypes"
            :key="index + 1"
            @click.native="changeSubtypeBadge(subtype)"
          >
            <tag
              :title="subtype.name"
              :active="activeSubtypeBadge === subtype.id"
            >
            </tag>
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ObjectCard from "@/components/Parts/ObjectCard";
import Tag from "@/components/Parts/Tag";

export default {
  components: {
    ObjectCard,
    Tag
  },
  data() {
    return {
      activeBadge: {
        type: "all",
        name: "Все",
        id: 1,
      },
      activeSubtypeBadge: 0
    }
  },

  mounted() {
    this.GET_EXCURSIONS_AND_ROUTES();
  },

  methods: {
    ...mapActions([
      "GET_EXCURSIONS_AND_ROUTES",
      "GET_SUBTYPES",
      "GET_FILTER_TYPE",
      "GET_EXCURSIONS_AND_ROUTES",
      "GET_ROUTES_AND_EXCURSIONS_SUBTYPES",
      "GET_ROUTES",
      "GET_EXCURSIONS",
      "GET_TRIP_OR_PLACE"
    ]),

    async goToObject(object) {
      await this.GET_TRIP_OR_PLACE(object);

      this.$router.push({ path: `/object/${object.id}`,
        query: {
          object: object,
          title: "Другие экскурсии и маршруты"
        }
      });
    },

    changeBadge(tag) {
      this.activeBadge = tag;
      this.activeSubtypeBadge = null

      if (tag.type === "all") {
        this.GET_EXCURSIONS_AND_ROUTES();
      }

      if (tag.type === "route") {
        this.GET_ROUTES();
        this.GET_FILTER_TYPE({
          tagType: tag.type,
          tagId: tag.id
        });
      }

      if (tag.type === "excursion") {
        this.GET_EXCURSIONS();
        this.GET_FILTER_TYPE({
          tagType: tag.type,
          tagId: tag.id
        });
      }
    },

    changeSubtypeBadge(subtype) {
      this.GET_ROUTES_AND_EXCURSIONS_SUBTYPES({
        type: this.activeBadge.type,
        subtypeId: subtype.id
      })

      this.TYPE.some(el => {
        if (el.subtypes) {
          el.subtypes.filter(item => {
            if (item.id === subtype.id) {
              this.activeSubtypeBadge = item.id
            }
          })
        }
      })
    }
  },

  computed: {
    ...mapGetters([
      "EXCURSIONS_AND_ROUTES",
      "TYPE"
    ])
  }
}
</script>

<style lang="scss" scoped>
.excursion {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-flow: row wrap;
  height: 1555px;
  padding: 32px;
  background-color: #1e1e1e;
  overflow-x: hidden;
  overflow-y: auto;

  .card {
    width: 488px;
    margin-bottom: 48px;
  }
}

.tags {
  position: absolute;
  bottom: 176px;
  left: 0px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}
</style>
